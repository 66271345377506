import { PRODUCT_REVIEW_REWARD_TYPE } from '../constants/productReview';

const FORM_STATE = {
  INITIAL: 'initial',
  EMPTY: 'empty',
  EDITING: 'editing',
  INVALID: 'invalid',
  SUBMITTED: 'submitted',
};

const PRODUCT_TYPE = {
  PRODUCT_SET: 'ProductSet',
  PRODUCT: 'Product',
};

app.directive('productReviewCommentsForm', [
  '$http',
  'imageService',
  'staticResourceHost',
  'mainConfig',
  'productReviewCommentService',
  'pnotifyService',
  'ordersService',
  '$filter',
  function (
    $http,
    imageService,
    staticResourceHost,
    mainConfig,
    productReviewCommentService,
    pnotifyService,
    ordersService,
    $filter,
  ) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/product_review_comments/templates.product_review_comments_form.html'),
      scope: {
        currentOrderId: '=',
        allowUploadMedia: '=',
        productReviewRewardType: '=',
        productReviewRewardRule: '=',
      },
      link: function ($scope) {
        $scope.FORM_STATE = FORM_STATE;
        $scope.staticResourceHost = staticResourceHost;
        $scope.imageService = imageService;
        $scope.ordersService = ordersService;
        $scope.isRecaptchaEnabled = mainConfig.isRecaptchaEnabled;
        $scope.formState = FORM_STATE.INITIAL;

        const getOrder = function (orderId) {
          productReviewCommentService
            .getOrder(orderId)
            .then(function (response) {
              $scope.currentOrder = response.data;
              $scope.currentOrder.subtotal_items = filterOrderSubtotalItems(
                response.data.subtotal_items,
              );
              initFormData($scope.currentOrder);
            });
        };

        const checkDuplicatedProduct = (list, product) => {
          if (product.item_type === PRODUCT_TYPE.PRODUCT_SET) {
            return list.find((item) => item.item_id === product.item_id);
          }
          if (product.item_type === PRODUCT_TYPE.PRODUCT) {
            return list.find(
              (item) => item.object_data._id === product.object_data._id,
            );
          }
          return true;
        };

        const filterOrderSubtotalItems = (subtotalItems) => {
          return subtotalItems.reduce((result, currentItem) => {
            // POS exchange order, there will be both negative and positive quantity item
            if (currentItem.quantity < 0) {
              return result;
            }
            const isDuplicatedProduct = checkDuplicatedProduct(
              result,
              currentItem,
            );

            if (isDuplicatedProduct) {
              return result;
            }

            return result.concat([currentItem]);
          }, []);
        };

        const initFormData = function (order) {
          if (order.subtotal_items.length === 0) {
            $scope.formState = FORM_STATE.EMPTY;
            return;
          }

          $scope.formState = FORM_STATE.EDITING;
          $scope.productReviewComments = order.subtotal_items.map(function (
            item,
          ) {
            return {
              score: 0,
              comment: '',
              product_id: item.object_data._id,
              medias: [],
            };
          });
          // focus a textarea on mobile devices to make it scroll to the form
          const textarea = document.getElementsByTagName('textarea')[0];
          if (textarea) {
            textarea.focus();
          }
        };

        $scope.$watch('currentOrderId', function (newValue) {
          getOrder(newValue);
        });

        $scope.submitForm = function () {
          const invalidComment = $scope.productReviewComments.some(function (
            comment,
          ) {
            return comment.score == 0;
          });
          if (invalidComment) {
            $scope.formState = FORM_STATE.INVALID;
            return;
          }

          const payload = {
            user_id: mainConfig.currentUser._id,
            order_id: $scope.currentOrder._id,
            product_review_comments: $scope.productReviewComments.map(function (
              formData,
            ) {
              return {
                score: formData.score,
                comment: formData.comment,
                product_id: formData.product_id,
                media_ids: formData.medias.map(function (media) {
                  return media._id;
                }),
              };
            }),
          };
          $http({
            method: 'POST',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            url:
              '/api/merchants/' +
              mainConfig.merchantId +
              '/orders/' +
              $scope.currentOrder._id +
              '/product_review_comments/bulk',
            data: payload,
          })
            .then(function () {
              $scope.formState = FORM_STATE.SUBMITTED;
            })
            .catch(function (err) {
              pnotifyService.notify(err.data.error, {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              });
            });
        };

        $scope.getFinishedReviewDesc = () => {
          switch ($scope.productReviewRewardType) {
            case PRODUCT_REVIEW_REWARD_TYPE.user_credit:
              return $filter('translate')(
                'product_review_comments.orders.finished_review.desc.user_credit',
                {
                  value: $scope.productReviewRewardRule.credit_value,
                },
              );
            case PRODUCT_REVIEW_REWARD_TYPE.member_point:
              return $filter('translate')(
                'product_review_comments.orders.finished_review.desc.member_point',
                {
                  value: $scope.productReviewRewardRule.point_value,
                },
              );
            case null:
              return '';
            default:
              throw Error(
                `unknown productReviewRewardType: ${$scope.productReviewRewardType}`,
              );
          }
        };
      },
    };
  },
]);
