import { CHECKOUT_EVENTS } from '../constants/angular-events';
import { BLOCK_STATUS } from '../constants/components/collapse-block-status';

app.directive("checkoutCartItems", [
  '$http'
  'checkoutService'
  'cartService'
  'trackerService'
  '$uibModal'
  '$compile'
  'slFeatureService'
  'logger'
  'mainConfig'
  'multiCheckoutService'
  'modalTypes'
  '$timeout'
  '$rootScope'
  'productStockService'
  'productService'
  '$q'
  (
    $http
    checkoutService
    cartService
    trackerService
    $uibModal
    $compile
    slFeatureService
    logger
    mainConfig
    multiCheckoutService
    modalTypes
    $timeout
    $rootScope
    productStockService
    productService
    $q
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        scope.errorItems = []
        scope.itemCollapsed = {}

        scope.$on CHECKOUT_EVENTS.CART.ITEMS.RELOAD, () ->
          element.addClass "checkout-section-loading"
          checkoutService.requestPartial('cart', 'items')
            .then (res) ->
              element.html($compile(presetContent(res.data))(scope))

              cartPageScope =
                if multiCheckoutService.isEnabled()
                  scope.$parent.$parent
                else
                  scope.$parent

              cartPageScope.$broadcast(CHECKOUT_EVENTS.CART.ADDONS.RELOAD)
              $timeout(setVariationIdError)
            .catch (error) ->
              logger.log("Unable to load cart items")
            .finally () ->
              checkCartItemStatus()

        updateCart = _.debounce(((itemId, productId, variationId, quantity) ->
          scope.$emit(CHECKOUT_EVENTS.CART.ITEMS.CHANGING)
          if quantity == 0 #remove item
            # bundlePricing remove logic in cart
            cartService.removeItem(itemId, cartItemValidate, { pageType: 'orderEdit' }, {
              skip_calculate_order: false,
              cache_key: mainConfig.currentPath,
              is_cart_page: true,
            })
          else # update item
            data =
              item:
                id: itemId
                variation_id: variationId
                quantity: quantity
              cart_options:
                skip_calculate_order: false
            checkoutService.requestPartial('cart', 'items', data)
              .then (res) ->
                cartService.fetchItems((() ->
                  cartService.tracking('UpdateItem', cartService.getTrackItem(productId, variationId), { pageType: 'orderEdit' })),
                { cache_key: mainConfig.currentPath }
                )
                scope.$emit CHECKOUT_EVENTS.CART.ITEM.UPDATED
                cartItemValidate()
              .catch (error) ->
                scope.$emit CHECKOUT_EVENTS.CART.NOTHING.CHANGED, { code: error.status }
                element.html($compile(error.data)(scope))
              .finally () ->
                checkCartItemStatus()
        ), 500)

        presetContent = (htmlString) -> presetMultiCartErrors(presetCollapsed(htmlString))

        presetCollapsed = (htmlString) ->
          if !multiCheckoutService.isEnabled()
            return htmlString

          $content = $('<div>' + htmlString + '</div>');

          Object.entries(scope.itemCollapsed).forEach(([blockId, collapseStatus]) ->
            $content.find('[block-id="' + blockId + '"]').attr('collapse-status', collapseStatus);
          );

          return $content.html();

        presetMultiCartErrors = (htmlString) ->
          if !multiCheckoutService.isEnabled()
            return htmlString

          $content = $('<div>' + htmlString + '</div>');

          Object.entries(scope.multiCart.errors).forEach(([cart_tag_id, errors]) ->
            return if _.isEmpty(errors)

            $errorWrapper = $content.find('.multi-cart-error__wrapper[data-cart-tag-id="' + cart_tag_id + '"]')
            $errorBlock = $errorWrapper.find('.multi-cart-error__block')
            $errorContent = Object.values(errors).map((err) ->
              $errorEl = $content.find("#multi-cart-error__item-template").clone()
              $errorEl.find('.multi-cart-error__item-text').html(err)
              $errorEl.html()
            ).join('')

            $errorWrapper.removeClass('hidden')
            $errorBlock.html($errorContent)
            $content.find('[block-id="' + cart_tag_id + '"]').attr('block-status', BLOCK_STATUS.ERROR)
          )

          return $content.html();

        scope.onItemCollapseChange = ({ blockId, collapseStatus }) ->
          scope.itemCollapsed[blockId] = collapseStatus

        checkCartItemStatus = () ->
          $timeout(() -> 
            element.removeClass "checkout-section-loading"
            setExcluedPromotionItemsHint()
            if element.find('.cart-item').length <= 0
              scope.$emit CHECKOUT_EVENTS.CART.EMPTY
            else
              scope.$emit CHECKOUT_EVENTS.CART.CONTENT.LOADED
          )

        scope.$on CHECKOUT_EVENTS.CART.ITEMS.VALIDATED, ($event, payload) ->
          scope.errorItems = payload.cartValidateItem.items

        cartItemValidate = () ->
          scope.errorItems = []
          cartService.validate()
            .then (data) ->
              scope.$emit(CHECKOUT_EVENTS.CART.ITEMS.CHANGED, { success: true })
            .catch (response) ->
              scope.errorItems = response.items
              scope.$emit(CHECKOUT_EVENTS.CART.ITEMS.CHANGED, {
                success: false, cartValidateItem: response
              })

        cartItemValidate()

        setVariationIdError = () ->
          scope.errorItems.forEach (item) ->
            if item.cart_item_ids && item.cart_item_ids.length > 0
              item.cart_item_ids.forEach (cartItemId) ->
                cartItemElement = element.find('[data-item-id="' + cartItemId + '"]')
                if cartItemElement.length && item.code != 'GIFT_VARIATION_REQUIRED'
                  cartItemElement.find('.item-information, .item-price, .item-total').addClass('disable')
                  cartItemElement.find('.fa-trash-o').addClass('text-danger')
            else
              cartItemElement = element.find('[product-id=' + item.item_id + '][data-variation-id="' + item.variation_id + '"]')
              if cartItemElement.length && item.variation_id?
                cartItemElement.find('.item-information, .item-price, .item-total').addClass('disable')
                cartItemElement.find('.fa-trash-o').addClass('text-danger')

        setExcluedPromotionItemsHint = () ->
          element.find('.promotion-excluded-item').each (index, item) ->
            $compile(item)(scope)

        getItemInfo = ($cartItem) ->
          itemId = $cartItem.attr("data-item-id")
          productId = $cartItem.attr("product-id")
          variationId = $cartItem.attr("data-variation-id")
          itemKey = productId + ':' + variationId
          errorType = $cartItem.find('.checkout-label.error').attr('data-error-type')

          return { itemId, productId, variationId, itemKey, errorType }

        onQuantityChange = (event, offset) ->
          $cartItem = angular.element(event.currentTarget).closest(".cart-item")
          { itemId, productId, variationId, itemKey } = getItemInfo($cartItem)

          if scope.getItemIsLockedByKey(itemKey)
            return
          
          $container = angular.element(event.currentTarget).closest(".item-quantity")
          $input = $container.find(".input-item_quantity")
          quantity = parseInt($input.val(), 10)
          return if _.isNaN(quantity)
          if offset?
            quantity = Math.min(Math.max(0, quantity + offset), 99999)
            $input.val(quantity)

          updateCart(itemId, productId, variationId, quantity)

          targetItem = cartService.getItemById(itemId)
          slPixelActionName = if +offset > 0 then 'addToCart' else 'removeFromCart'
          cartService.sendSlPixelTracking(slPixelActionName, targetItem, Math.abs(+offset)) if quantity > 0

        onRemoveItem = (event) ->
          $cartItem = angular.element(event.currentTarget).closest(".cart-item")
          { itemId, productId, variationId, itemKey, errorType } = getItemInfo($cartItem)
          isCheckoutDisabledError = [cartService.QUANTITY_VERIFY_STATUS.OUT_OF_STOCK, cartService.QUANTITY_VERIFY_STATUS.LOW_STOCK].includes(errorType)

          if scope.getItemIsLockedByKey(itemKey) && !isCheckoutDisabledError
            $uibModal.open({
              templateUrl: require('../../../../../public/themes/v1/default/views/messages.lock-cart-sc-product.modal.html'),
              controller: ['$scope', '$uibModalInstance' , ($scope, $uibModalInstance) ->
                $scope.confirm = () -> $uibModalInstance.close()
              ]
            })
            return

          scope.currentModal = $uibModal.open({
            templateUrl: require('../../../../../public/themes/v1/default/views/messages.confirm.modal.html'),
            controller: ['$scope', '$uibModalInstance' , ($scope, $uibModalInstance) ->
              $scope.cancel = () -> $uibModalInstance.dismiss('cancel')
              $scope.submitConfirm = () -> $uibModalInstance.close()
            ]
          })
          scope.currentModal.result.then () -> removeItem(itemId)

        removeItem = (itemId) ->
          cartService.removeItem(itemId, cartItemValidate, { pageType: 'orderEdit' }, {
            skip_calculate_order: false,
            cache_key: mainConfig.currentPath,
            is_cart_page: true,
          })

        element.on "click", ".btn-quantity-decrease", ((event) -> onQuantityChange(event, -1))
        element.on "click", ".btn-quantity-increase", ((event) -> onQuantityChange(event, 1))
        element.on "input", ".input-item_quantity", ((event) -> onQuantityChange(event))

        element.on "click", ".btn-remove-cart-item", ((event) -> onRemoveItem(event))

        scope.itemToEvent = {};
        # because it only load simplified data initially, we have to fetch again to get complete data
        cartService.fetchItems();
        scope.$on('cartService.fetch', () -> 
          scope.itemToEvent = cartService.getItemToEvent()
        )

        scope.getItemIsLockedByKey = (itemKey) ->
          return !!scope.itemToEvent[itemKey]?.isLocked

        element.on "destroy", (() -> element.off()) # Unbind events

        variationModalOpened = (newScope) ->
          modal = $uibModal.open({
            templateUrl:
              require('../../../../../public/themes/v1/default/views/templates.dialog.product.quick_cart.html')
            controller: 'productVariationPickerController'
            scope: newScope
            windowClass: 'QuickCart-modal product-set-quick-cart-modal product-variation-picker'
          });
          modal.opened.then(() ->
            $rootScope.$emit('modal.open', { modalType: modalTypes.QUICK_CART_PRODUCT_SET })
          )

          modal.closed.then(() ->
            cartItemValidate()
          )

          modal.result.then(() ->
            cartItemValidate()
          , () -> {}
          )

        scope.openVariationModal = (id, promotionId) ->
          cartService.prepareCart().then(() ->
            $q.all([
              productService.getById(id)
              cartService.getCartItemsByFetch()
            ]).then((res) ->
              newScope = scope.$new();
              newScope.product = res[0].data.data;
              newScope.cart = res[1].data.data;
              newScope.promotionId = promotionId;
              newScope.product.hide_price = false;
              hasCartItem = newScope.cart && newScope.cart.items && newScope.cart.items.some(
                (item) ->
                  item.applied_promotion && promotionId == item.applied_promotion.id &&
                  item.product_id == newScope.product._id
              )
              if hasCartItem
                # check channel stock
                if newScope.cart.delivery_data && newScope.cart.delivery_data.target_channel_id
                  variationCheckArray = newScope.product.variations.map (variation) ->
                    return { product_id: newScope.product._id, variation_id: variation.key, target_channel_id: newScope.cart.delivery_data.target_channel_id }

                  productService.checkStocks(variationCheckArray).then((stocks) ->
                    newScope.product.variations = newScope.product.variations.map((variation, index) -> ({ ...variation, quantity: stocks.data[index].quantity }))
                    variationModalOpened(newScope)
                  )
                else
                  variationModalOpened(newScope)
              else
                cartItemValidate()
            )
          )
    }
])
