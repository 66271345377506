import { isEqual } from 'lodash-es';

app.directive('categoryFilter', [
  '$filter',
  '$location',
  'mainConfig',
  'advanceFilterData',
  'categoryFilterService',
  function (
    $filter,
    $location,
    mainConfig,
    advanceFilterData,
    categoryFilterService,
  ) {
    return {
      restrict: 'E',
      scope: {
        type: '@',
      },
      templateUrl: require('../../../../../public/themes/shared/category/templates.category_filter.html'),
      link: function (scope) {
        if (!scope.type) {
          scope.type = categoryFilterService.isDrawerType()
            ? 'drawer-category-filter'
            : 'mobile-category-filter';
        }

        var initData = function () {
          scope.currentLanguageCode = mainConfig.localeData.loadedLanguage.code;
          scope.baseCurrency =
            mainConfig.merchantData.base_currency.alternate_symbol;
          scope.advanceFilterTypes = categoryFilterService.getFilterTypes();
          scope.displayItemCount = 8;
          scope.maxSelectedCount = 20;
          scope.currentSearchParams = $location.search();
          scope.activeFilterData = getActiveFilterData();
          scope.priceRange = {
            min: null,
            max: null,
          };
          setPriceRangeData();

          scope.$watch(
            'priceRange',
            function (newPriceRange) {
              Object.keys(newPriceRange).forEach(function (key) {
                if (newPriceRange[key]) {
                  scope.priceRange[key] = newPriceRange[key]
                    .replace(/[^.\d]/g, '')
                    .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
                }
              });
              scope.hasPriceRange =
                scope.priceRange.min || scope.priceRange.max;
            },
            true,
          );

          scope.$watch(
            'activeFilterData',
            function () {
              scope.selectedItems = scope.activeFilterData.reduce(
                function (group, data) {
                  var type = data.type;
                  if (!group[type]) {
                    group[type] = {
                      selectedIds: [],
                    };
                  }

                  var selectedIds = data.items
                    .filter(function (item) {
                      return item.selected;
                    })
                    .map(function (selectedItem) {
                      return selectedItem.id;
                    });

                  group[type]['selectedIds'] = group[type][
                    'selectedIds'
                  ].concat(selectedIds);
                  group[type][data.id] = selectedIds;
                  group.count += selectedIds.length;
                  return group;
                },
                { count: 0 },
              );
            },
            true,
          );
        };

        var setPriceRangeData = function () {
          var currentSearchParams = $location.search();
          scope.priceRange.min = currentSearchParams.min_price;
          scope.priceRange.max = currentSearchParams.max_price;
        };

        var getActiveFilterData = function () {
          return advanceFilterData.map(function (data) {
            /*
              filter data:
              1. type color and size has current language translate data
              2. item count > 0
            */
            data.items = data.items.filter(function (item) {
              var hasData = item.count;
              if (['color', 'size'].includes(data.type)) {
                return (
                  hasData &&
                  Object.keys(item.content_translations).includes(
                    scope.currentLanguageCode,
                  )
                );
              }
              return hasData;
            });

            /* set select status */
            data.items.forEach(function (item) {
              if (!item.id) {
                item.id = $filter('translateModel')(item.content_translations);
              }

              var hasSearchData =
                data.type === 'filter_tag'
                  ? scope.currentSearchParams[data.type + '[' + data.id + '][]']
                  : scope.currentSearchParams[data.type + '[]'];

              if (hasSearchData) {
                if (Array.isArray(hasSearchData)) {
                  item.selected = hasSearchData.includes(item.id);
                } else {
                  item.selected = hasSearchData === item.id;
                }
              } else {
                item.selected = false;
              }
            });

            data.expand = true;
            data.showMore = false;

            return data;
          });
        };

        const checkHasKeyInActiveFilterData = (key) => {
          const { activeFilterData } = scope;
          const activeFilterKeys = activeFilterData.map((item) => item.type);
          const hasFilterKey = activeFilterKeys.find((filterKey) =>
            key.includes(`${filterKey}[`),
          );
          return !!hasFilterKey;
        };

        const getFilterDataBySearch = function () {
          const { currentSearchParams } = scope;
          const filterData = {};
          const searchParamsKeys = Object.keys(currentSearchParams);

          searchParamsKeys.forEach((searchParamsKey) => {
            const hasFilterKey = checkHasKeyInActiveFilterData(searchParamsKey);
            if (hasFilterKey) {
              // When there are multiple data, converted to an array
              const value = currentSearchParams[searchParamsKey];
              filterData[searchParamsKey] =
                typeof value === 'string' ? [value] : value;
            }
          });

          // The price parameter on the url is a string and needs to be converted
          if (
            Object.getOwnPropertyDescriptor(currentSearchParams, 'max_price')
          ) {
            filterData.max_price = Number(currentSearchParams.max_price);
          }
          if (
            Object.getOwnPropertyDescriptor(currentSearchParams, 'min_price')
          ) {
            filterData.min_price = Number(currentSearchParams.min_price);
          }

          return filterData;
        };

        scope.toggleItemExpand = function (data) {
          data.expand = !data.expand;
        };

        scope.clearItemSelection = function (index) {
          scope.activeFilterData[index].items.forEach(function (item) {
            item.selected = false;
          });
        };

        scope.clearPriceRange = function () {
          scope.priceRange.min = null;
          scope.priceRange.max = null;
        };

        scope.selectedCount = function () {
          return scope.selectedItems.count + (scope.hasPriceRange ? 1 : 0);
        };

        scope.applyFilter = function () {
          /*
            search params may look like this:
            scope=advanced_filter&filter_tag[aa][]=111&filter_tag[aa][]=222&filter_tag[bb][]=11&material[]=乳液&min_price=123&max_price=321"
          */
          var newFilterSearchParams = Object.keys(scope.selectedItems).reduce(
            function (group, key) {
              if (key === 'count') return group;

              if (key === 'filter_tag') {
                Object.keys(scope.selectedItems[key]).forEach(function (
                  settingId,
                ) {
                  if (
                    settingId !== 'selectedIds' &&
                    scope.selectedItems[key][settingId].length
                  ) {
                    group[key + '[' + settingId + '][]'] =
                      scope.selectedItems[key][settingId];
                  }
                });
              } else if (scope.selectedItems[key]['selectedIds'].length) {
                group[key + '[]'] = scope.selectedItems[key]['selectedIds'];
              }

              return group;
            },
            {},
          );

          if (scope.priceRange.min) {
            newFilterSearchParams['min_price'] = parseFloat(
              scope.priceRange.min,
            );
          }

          if (scope.priceRange.max) {
            newFilterSearchParams['max_price'] = parseFloat(
              scope.priceRange.max,
            );
          }
          const queryData = getFilterDataBySearch();

          if (isEqual(newFilterSearchParams, queryData)) {
            scope.closeMobileFilterWindow();
            return;
          }

          Object.keys(scope.currentSearchParams).forEach(function (key) {
            var isFilterTag = /filter_tag/.test(key);
            if (isFilterTag) {
              delete scope.currentSearchParams[key];
            }
            if (scope.advanceFilterTypes.includes(key)) {
              delete scope.currentSearchParams[key];
            }
          });

          /* remove search keyword */
          delete scope.currentSearchParams.query;
          delete scope.currentSearchParams.page;
          delete scope.currentSearchParams.scope;

          if (Object.keys(newFilterSearchParams).length > 0) {
            newFilterSearchParams.scope = 'advanced_filter';
          }

          $location.search(
            Object.assign(scope.currentSearchParams, newFilterSearchParams),
          );
          window.location.href = $location.absUrl();
        };

        scope.closeMobileFilterWindow = function () {
          $('.category-advance-filter').removeClass('open');
        };

        if (advanceFilterData) {
          initData();
        }
      },
    };
  },
]);
