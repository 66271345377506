import { getInstalledAppIds } from '../../sdk';
import {
  InvalidArgumentError,
  ComponentNotFoundError,
  InvalidAppIdError,
  InvalidActionError,
} from '../../errors';

import BaseComponent from './BaseComponent';

const slotContainerClass = 'sl-component-extension-slot-container';
const slotContainerDatasetPositionKey = 'position';
const elementSlotClass = 'sl-component-extension-slot';
const elementDatasetAppIdKey = 'appId';
const hiddenClass = 'sl-component-extension-hidden';
const hiddenAppIdsDatasetKey = 'slComponentExtensionHiddenByAppIds';

function _createSlotContainer(componentName, position) {
  const slot = document.createElement('div');
  slot.classList.add(slotContainerClass);
  slot.dataset[
    slotContainerDatasetPositionKey
  ] = `${componentName}__${position}`;
  slot.setAttribute('ng-non-bindable', '');

  return slot;
}

function _createElementWrapper(appId) {
  const wrapper = document.createElement('div');
  wrapper.classList.add(elementSlotClass);
  wrapper.dataset[elementDatasetAppIdKey] = appId;

  return wrapper;
}

class Component extends BaseComponent {
  mount(position, element) {
    if (!this.exist()) {
      throw new ComponentNotFoundError(this._schema.name);
    }

    if (position !== 'before' && position !== 'after') {
      throw new InvalidArgumentError(
        "Invalid argument: `position` should only be 'before' or 'after'",
        this._schema.name,
      );
    }

    if (element?.nodeType !== Node.ELEMENT_NODE) {
      throw new InvalidArgumentError(
        'Invalid argument: `element` should only be an HTML Element Node',
        this._schema.name,
      );
    }

    const installedAppIds = getInstalledAppIds();
    const appIdToIndexMap = installedAppIds.reduce((map, appId, i) => {
      map[appId] = i;
      return map;
    }, {});

    const currentAppIndex = appIdToIndexMap[this._context.appId];
    // Throw error if the app id is not in the list
    if (currentAppIndex === undefined) {
      throw new InvalidAppIdError();
    }

    const slotContainer = this._getSlot(position, true);
    const wrapper = _createElementWrapper(this._context.appId);
    wrapper.append(element);

    // Sort by app installation time
    let targetEle = null;
    // Get all elements
    const allElements = slotContainer.querySelectorAll(`.${elementSlotClass}`);
    for (const ele of allElements) {
      const eleAppId = ele.dataset[elementDatasetAppIdKey];
      const eleAppIndex = appIdToIndexMap[eleAppId];
      if (eleAppIndex > currentAppIndex) {
        break;
      }
      targetEle = ele;
    }

    if (!targetEle) {
      // Insert at start
      slotContainer.insertBefore(wrapper, slotContainer.firstChild);
    } else {
      targetEle.after(wrapper);
    }
  }

  unmount(position, element) {
    if (!this.exist()) {
      throw new ComponentNotFoundError(this._schema.name);
    }
    if (position !== 'before' && position !== 'after') {
      throw new InvalidArgumentError(
        "Invalid argument: `position` should only be 'before' or 'after'",
        this._schema.name,
      );
    }

    const slotContainer = this._getSlot(position, false);
    if (!slotContainer) return;

    const allElements = slotContainer.querySelectorAll(
      `.${elementSlotClass}[data-app-id="${this._context.appId}"]`,
    );
    for (const ele of allElements) {
      if (ele.children?.[0] === element) {
        // Element found
        ele.remove();
        break;
      }
    }

    if (slotContainer.childElementCount === 0) {
      // Also remove slot container
      slotContainer.remove();
    }
  }

  hide() {
    if (!this.exist()) {
      throw new ComponentNotFoundError(this._schema.name);
    }

    if (!this._schema.options?.hideable) {
      throw new InvalidActionError(this._schema.name, 'hide');
    }

    const hideElements = this._getElementNodesWithinRange();
    for (let ele of hideElements) {
      ele.classList.add(hiddenClass);
      const hiddenAppIds = ele.dataset[hiddenAppIdsDatasetKey];
      const appIdsArr = hiddenAppIds?.split(',') || [];
      if (appIdsArr.indexOf(this._context.appId) === -1) {
        appIdsArr.push(this._context.appId);
      }
      ele.dataset[hiddenAppIdsDatasetKey] = appIdsArr.join(',');
    }
  }

  unhide() {
    if (!this.exist()) {
      throw new ComponentNotFoundError(this._schema.name);
    }

    if (!this._schema.options?.hideable) {
      throw new InvalidActionError(this._schema.name, 'unhide');
    }

    const unhideElements = this._getElementNodesWithinRange();
    for (let ele of unhideElements) {
      const hiddenAppIds = ele.dataset[hiddenAppIdsDatasetKey];
      const appIdsArr = hiddenAppIds?.split(',') || [];
      const newAppIdsArr = appIdsArr.filter(
        (appId) => appId !== this._context.appId,
      );
      if (newAppIdsArr.length > 0) {
        // Still have other apps that request hidden, element will remain hidden
        // Will only update the app ids
        ele.dataset[hiddenAppIdsDatasetKey] = newAppIdsArr.join(',');
        continue;
      }

      delete ele.dataset[hiddenAppIdsDatasetKey];
      ele.classList.remove(hiddenClass);
    }
  }

  _getElementNodesWithinRange() {
    if (this._node.nodeType === Node.ELEMENT_NODE) {
      return [this._node];
    }

    const elements = [];
    let node = this._node.begin;
    while (node !== null && node !== this._node.end.nextElementSibling) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        elements.push(node);
      }
      node = node.nextElementSibling;
    }

    return elements;
  }

  _getSlot(position, create = false) {
    let slotNode;

    if (position == 'before') {
      let beginNode = this._node.begin;

      slotNode = beginNode.previousElementSibling;
      if (slotNode && slotNode.classList.contains(slotContainerClass)) {
        return slotNode;
      }

      // Create slot container
      if (create) {
        slotNode = _createSlotContainer(this._schema.name, position);
        this._node.begin.before(slotNode);
      }
    } else if (position == 'after') {
      let endNode = this._node.end;

      slotNode = endNode.nextElementSibling;
      if (slotNode && slotNode.classList.contains(slotContainerClass)) {
        return slotNode;
      }

      // Create slot container
      if (create) {
        slotNode = _createSlotContainer(this._schema.name, position);
        this._node.end.after(slotNode);
      }
    }

    return slotNode;
  }
}

export default Component;
